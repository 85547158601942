@font-face {
  font-family: "Circular Pro";
  src: url("../fonts/circular-pro/crp-book.woff2") format("woff2"),
    url("../fonts/circular-pro/crp-book.woff") format("woff");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Circular Pro";
  src: url("../fonts/circular-pro/crp-medium.woff2") format("woff2"),
    url("../fonts/circular-pro/crp-medium.woff") format("woff");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Circular Pro";
  src: url("../fonts/circular-pro/crp-bold.woff2") format("woff2"),
    url("../fonts/circular-pro/crp-bold.woff") format("woff");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Nib";
  src: url("../fonts/nib/nib-regular.woff2") format("woff2"),
    url("../fonts/nib/nib-regular.woff") format("woff");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Nib";
  src: url("../fonts/nib/nib-italic.woff2") format("woff2"),
    url("../fonts/nib/nib-italic.woff") format("woff");
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Nib";
  src: url("../fonts/nib/nib-semibold.woff2") format("woff2"),
    url("../fonts/nib/nib-semibold.woff") format("woff");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@keyframes bounce {
  0% {
    transform: scale3d(1, 1, 1);
  }
  25% {
    transform: scale3d(1.3, 1.3, 1.3);
  }
  50% {
    transform: scale3d(1, 1, 1);
  }
  75% {
    transform: scale3d(1.3, 1.3, 1.3);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
